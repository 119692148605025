<template>
  <div class="parkdetail_container_box">
    <!-- 头部 -->
    <div class="top_box">
      <span class="top_span_a">停车时长</span>
      <span class="top_span_b">{{ time }}</span>
    </div>
    <!-- 列表 -->
    <div class="top_middle_box">
      <div class="item_box">
        <span>用户余额</span>
        <span
          ><em>{{ order.balance }}</em
          >元</span
        >
      </div>
      <div class="item_box">
        <span>停车区域</span>
        <span>{{ order.sectionName }}</span>
      </div>
      <div class="item_box">
        <span>开始时间</span>
        <span>{{ order.startTime }}</span>
      </div>
      <div class="item_box">
        <span>泊位号</span>
        <span>{{ berthnum }}</span>
      </div>
    </div>

    <!-- 查看相关余额，进入小程序 -->
    <div v-if="isBindCar && isWeixin=='micromessenger'">
      <wx-open-launch-weapp
        id="launch-btn"
        username="gh_91d425b0b120"
        path="/pages/entrance/entrance.html"
        style="width: 345px; height: 48px; text-align: center; font-size: 18px"
      >
        <script v-is="'script'" type="text/wxtag-template">
              <style v-is="'style'">
                  .btn{
                      border: none;
                      margin-top: 50px;
                      margin-bottom: 50px;
                      width: 345px;
                      height: 48px;
                      background-image: linear-gradient(51deg, #1991A3 0%, #7CB630 100%);
                      border-radius: 24px;
                      font-family: PingFangSC-Medium;
                      font-weight: 500;
                      font-size: 18px;
                      color: #FFFFFF;
                      text-align: center;
                      line-height: 48px;
                  }
              </style>
          <button class="btn"> 查看相关订单，进入小程序</button>
        </script>
      </wx-open-launch-weapp>
    </div>
    <div v-if="isBindCar && isWeixin=='alipay'" class="btn" @click="goAlipay('order')">
      查看相关订单，进入小程序
    </div>
    <!-- 去绑定车牌 -->
    <div v-if="!isBindCar && isWeixin=='micromessenger'">
      <wx-open-launch-weapp
        id="launch-btn"
        username="gh_91d425b0b120"
        path="/pages/minecar/minecar.html"
        style="width: 345px; height: 48px; text-align: center; font-size: 18px"
      >
        <script v-is="'script'" type="text/wxtag-template">
              <style v-is="'style'">
                  .btn{
                      border: none;
                      margin-top: 50px;
                      margin-bottom: 50px;
                      width: 345px;
                      height: 48px;
                      background-image: linear-gradient(51deg, #1991A3 0%, #7CB630 100%);
                      border-radius: 24px;
                      font-family: PingFangSC-Medium;
                      font-weight: 500;
                      font-size: 18px;
                      color: #FFFFFF;
                      text-align: center;
                      line-height: 48px;
                  }
              </style>
          <button class="btn"> 添加车辆，进入小程序</button>
        </script>
      </wx-open-launch-weapp>
    </div>
    <div v-if="!isBindCar && isWeixin=='alipay'" class="btn" @click="goAlipay('carNumberList')">
      添加车辆，进入小程序
    </div>
    <!-- 弹框 -->
    <div class="enter_marker_box" v-if="isShow">
      <div class="enter_inner_box">
        <span class="inner_top_span">即将打开“台州停车”小程序</span>
        <div class="inner_botttom_box">
          <span @click="cancel">取消</span>
          <span>允许</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRoute } from "vue-router";
import {
  getberthOrderdetail,
  getH5WxLoginSign,
  queryPlateNoByUid,
} from "@/server/api.js";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  setup() {
    const routes = useRoute();
    const allData = reactive({
      isShow: false,
      order: {},
      berthnum: "",
      time: "00:00:00", //占位时间
      shentime: "",
      isBindCar: "", //是否绑定了车牌
      isWeixin: "micromessenger"
    });
    const markershow = () => {
      allData.isShow = true;
    };
    const cancel = () => {
      allData.isShow = false;
    };
    onMounted(() => {
      allData.isWeixin =isWeixin()
      // 判断当前用户是否绑定车牌
      queryPlateNoByUid({ pageNum: 1, pageSize: 100 }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          if (res.data.length > 0) {
            allData.isBindCar = true; //绑定了车牌
          } else {
            allData.isBindCar = false;
          }
        }
      });

      // 微信公众号获取签名
      allData.pathurl = location.href.split("#")[0];
      getH5WxLoginSign({ url: allData.pathurl }).then((res) => {
        console.log(res);
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识 gh_298d2d1bf9ee   wx3d385eb9ae7f6482
          timestamp: res.data.timestamp.toString(), // 必填，生成签名的时间戳
          nonceStr: res.data.noncestr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            "openTagList",
            "onMenuShareTimeline",
            "onMenuShareAppMessage",
            "onMenuShareQQ",
            "onMenuShareQZone",
            "hideOptionMenu",
          ], //必填，需要使用的JS接口列表1
          openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        });
        // 处理成功验证
        wx.ready(function () {
          console.log("wx.ready-success");
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
        });
        // 处理失败验证
        wx.error(function (res) {
          console.log(res);
          console.log("error fail");
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        });

        var btn = document.getElementById("launch-btn");
        btn.addEventListener("launch", function (e) {
          console.log("success", e);
        });
        btn.addEventListener("error", function (e) {
          console.log("fail", e.detail);
        });
      });

      // getberthOrderdetail
      allData.berthnum = routes.query.bert;

      getberthOrderdetail({ berthCode: routes.query.bert }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          allData.order = res.data;
          setInterval(() => {
            var a = res.data.startTimeStamp;
            var date = new Date(); //获取当前时间
            var centerTime = date.getTime(); //当前的时间戳
            if (a < centerTime) {
              allData.shentime = centerTime - a; //剩余时间戳
            } else {
              allData.shentime = a - centerTime; //剩余时间戳
            }
            allData.time = dateformat(allData.shentime);
          }, 1000);
        } else {
          Toast(res.msg);
        }
      });
    });
    // 时间戳转为  时分秒
    const dateformat = (micro_second) => {
      // 总秒数
      var second = Math.floor(micro_second / 1000);
      // 天数
      var day = Math.floor(second / 3600 / 24);
      // 小时
      var hr = Math.floor((second / 3600) % 24);
      // 分钟
      var min = Math.floor((second / 60) % 60);
      // 秒
      var sec = Math.floor(second % 60);
      return day + "天" + hr + "时" + min + "分" + sec + "秒";
    };
    // 判断当前浏览器是不是微信浏览器
    const isWeixin = () => {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return "micromessenger";
      } else if (ua.match(/Alipay/i) == "alipay") {
        return "alipay";
      } else {
        return false;
      }
    };
    // 跳转支付宝小程序
    const goAlipay = (page) =>{
      window.location.href = "alipays://platformapi/startapp?appId=2021002170682296&page=pages/"+ page +"/" +page
    }
    return {
      ...toRefs(allData),
      markershow,
      cancel,
      goAlipay
    };
  },
};
</script>

<style scoped>
.parkdetail_container_box {
  width: 100vw;
  height: 100vh;
  background: #f3f6f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top_box {
  width: 100%;
  height: 123px;
  margin-top: 8px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top_span_a {
  margin-top: 30px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #363a44;
  text-align: center;
}
.top_span_b {
  margin-top: 8px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 24px;
  color: #363a44;
  text-align: center;
}

/* 列表 */
.top_middle_box {
  margin-top: 8px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item_box {
  width: 92%;
  height: 56px;
  border-bottom: 1px solid #e8e9ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item_box > span {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #363a44;
}
.item_box > span > em {
  font-style: normal;
  color: #ff1e00;
}
.enter_wechart {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 92%;
  height: 48px;
  background-image: linear-gradient(51deg, #1991a3 0%, #7cb630 100%);
  border-radius: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
}

/* 弹框 */
.enter_marker_box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.enter_inner_box {
  width: 80%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.inner_top_span {
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  font-size: 18px;
}
.inner_botttom_box {
  border-top: 1px solid #e8e9ec;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inner_botttom_box > span {
  width: 50%;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.btn{
  border: none;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 345px;
  height: 48px;
  background-image: linear-gradient(51deg, #1991A3 0%, #7CB630 100%);
  border-radius: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  line-height: 48px;
}
</style>