var newRoot = "https://parkback.tzzntc.com"
// var newRoot = "https://testparkback.tzzntc.com"
export default{
    "getiphonecode": newRoot +"/auth/sendPhoneVerifyCode",//通过 手机号 获取 短信验证码
    "codeInformation":newRoot + "/auth/getInfoByCode", //通过 code 获取用户信息
    "logonWeb":newRoot + "/auth/login", //登录接口
    "relievebind":newRoot+'/auth/removeBinding', //解除用户绑定

    "repeatStopcar":newRoot+'/curbparking/curbparkingProcess/checkRepeatParkingIn', //查看车辆是否重复停靠
    "getselfCarplateNo":newRoot+'/curbparking/curbparkingProcess/getPlateNoByBerthCode', //获取个人路内停车的车牌号
    "selfCurbpakring":newRoot+'/curbparking/curbparkingProcess/userParkingIn', //个人路内停车
    "getberthOrderdetail":newRoot+'/curbparking/curbparkingBerth/berthCarInfo', //获取个人路内停车泊位号订单详情
    "getH5WxConfig":newRoot+"/meb/memberUser/getH5WxConfig", //获取公众号h5授权微信登录重定向
    "getH5WxLoginSign": newRoot+'/meb/memberUser/getH5WxLoginSign', //获取微信公众号签名
    "getBerthByQrcode":newRoot+'/curbparking/curbparkingBerthQrcode/getBerthByQrcodeUrl', //通过 url 获取 泊位号

    "queryPlateNoByUid":newRoot + '/meb/car/list', //用户拨号完成后提示用户绑定车牌可不用拨号

    "ongoingOrder":newRoot + "/bill/parkingBillOrder/ongoingOrder",// 根据泊位获取订单信息
    "createUserPayLink":newRoot + "/bill/parkingBillOrder/createUserPayLink",// 根据泊位获取订单信息
    "createUserPayLinkDirectPay":newRoot + "/bill/parkingBillOrder/createUserPayLinkDirectPay", // 小票直接支付接口
    "getOrderByRecordId":newRoot+"/bill/parkingBillOrder/getOrderByRecordId",// 根据小票信息获取整个订单信息
    "overDueListByBerthNum":newRoot+"/bill/parkingBillOrder/overDueListByBerthNum"// 根据小票信息获取历史费用
}