<template>
    <div class="privacyaggrement_outbox">
        <p class="pa">隐私政策</p>
        <p class="pb">台州停车（简称“我们”）非常重视用户您的隐私和个人信息保护，我们将按照法律法规的规定，保护您的个人信息及隐私安全。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过《台州停车隐私政策》向您说明，在您使用我们的服务时，我们如何收集、使用、储存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。本隐私政策与您所使用台州停车服务息息相关，希望您仔细阅读，在确认充分了解并同意后使用台州停车服务。如您不同意本政策的任何内容,请您立即停止使用。当您使用时,即表示您已经同意本政策并信赖我们对您的信息处理方式。</p>
        <p class="pb">需要特别说明的是:本政策不适用于其他第三方向您提供的服务,当您通过我们使用第三方产品或接受服务时,您的信息将适用该第三方的隐私条款。我们会尽力要求第三方服务提供商为您的个人信息提供足够的安全保护水平并会在具体产品页面明确告知您服务由特定第三方提供,同时强烈建议您在使用上述第三方服务前先行了解、确认服务提供方的隐私政策。</p>
        <p class="pb">下文将帮您详细了解我们如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；帮您了解查询、访问、删除、更正、撤回授权个人信息的方式。其中，有关您个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。</p>
        <p class="pc">我们如何收集和使用个人信息</p>
        <p class="pc">我们如何使用cookie等同类技术</p>
        <p class="pc">我们如何共享、转让、公开披露个人信息</p>
        <p class="pc">我们如何存储个人信息</p>
        <p class="pc">我们如何保护个人信息的安全</p>
        <p class="pc">您如何管理、查看、删除本人的个人信息</p>
        <p class="pc">未成年人信息的保护</p>
        <p class="pc">本隐私政策的修订和通知</p>
        <p class="pc">如何联系我们（投诉处理）</p>
        <p class="pc">其他</p>
        <p class="pb">1我们如何收集和使用个人信息</p>
        <p class="pb">1.1我们如何收集个人信息</p>
        <p class="pb">我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</p>
        <p class="pb">1.1.1您在使用我们的服务时主动提供的个人信息</p>
        <p class="pb">a. 当您注册、登录台州停车及相关服务时，您可以用经过实名认证的手机号创建账号，您可以随时查看、修改、补充您的昵称、性别、生日以及您的车牌等相关信息，这些信息均属于您的“账户信息”。</p>
        <p class="pb">b. 您也可以使用第三方账号登录并使用，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称、地区以及性别信息），用于与台州停车账号绑定，使您可以直接登录并使用本产品和相关服务</p>
        <p class="pb">c. 在您使用身份认证的功能或相关服务所需时，根据相关法律法规，您可能需要提供您的真实身份信息（真实姓名、身份证号码、电话号码）以完成实名验证。这些信息属于个人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</p>
        <p class="pb">1.1.2您在使用我们的服务时上传的信息</p>
        <p class="pb">例如：您在使用我们服务时上传的头像、车场照片等图片信息。</p>
        <p class="pb">1.1.3 您通过我们的客服所提交的信息</p>
        <p class="pb">例如：客服与售后服务时，您有可能会在与我们的客服人员沟通时，提供注册信息外的其他信息，如当您申请变更地址、联系人或联系电话等，为保证您的账号安全，我们的呼叫中心客服和在线客服会要求您提供账号信息，用于核验您的身份。</p>
        <p class="pb">1.1.4. 我们在您使用服务时获取的信息</p>
        <p class="pb">(a) 设备信息：为了让您快速地找到您所需要的停车场、停车位信息，我们可能会收集您使用我们的产品和或服务的设备信息【包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN）】来为您提供停车场、停车位信息的最优方式。</p>
        <p class="pb">(b) 搜索内容：您可以通过搜索来精准地找到您需要的服务信息，我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的服务。</p>
        <p class="pb">请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何目的对其进行使用；只有当您的都是关键词信息与您的其他信息互相结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理和保护。</p>
        <p class="pb">(c) 停车服务交易信息：基于我们平台向您提供的智能停车服务以及支付服务形成的交易信息，包括您所驾驶的车辆入场记录、出场记录、停车场名称、车辆车牌信息、支付方式、支付金额等。</p>
        <p class="pb">(d) 车辆车牌信息：为了实现智慧停车的功能，我们需要识别并收集您的车辆外观及车牌信息，以便结算停车费。</p>
        <p class="pb">(e) 支付订单信息：您可以选择台州停车合作的第三方支付机构（包括微信支付及支付宝等支付通道，以下简称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。</p>
        <p class="pb">(f）其他相关信息：为了帮助您更好地使用我们的产品或服务，我们会收集相关的信息，例如浏览信息。</p>
        <p class="pb">1.1.5 我们从第三方合作伙伴获取的信息</p>
        <p class="pb">我们可能会获得您在使用第三方合作伙伴服务时所产生或分享的信息。例如，您使用台州停车账户登录第三方合作伙伴服务（查违章、车品商城、洗车保养等）时，我们会获得您登录第三方合作伙伴服务的名称、登录时间，方便您进行授权管理。请您仔细阅读第三方合作伙伴服务的用户协议或隐私政策。</p>
        <p class="pb">我们可能从第三方获取您授权共享的账户信息（头像、昵称、账号等），并在您同意本隐私政策后将您的第三方账户与您的台州停车账户绑定或关联，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</p>
        <p class="pb">您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。</p>
        <p class="pb">1.2我们如何使用您的个人信息</p>
        <p class="pb">1.2.1使用目的和方式</p>
        <p class="pb">我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。向您提供服务。</p>
        <p class="pb">产品开发和服务优化。为了提供个性化内容及改进服务质量等目的而获得的您的个人信息；对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。</p>
        <p class="pb">为保障交易安全所必须的功能。为提高我们产品与/或服务系统的安全性，预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解并分析您的浏览信息、订单信息、您常用的软件信息、设备信息等判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”），排查可能存在的风险。</p>
        <p class="pb">向您推荐您可能感兴趣的广告、资讯等。我们通过收集您的历史动态信息，通过短信、公众号号推送等向您推荐您可能感兴趣的广告、资讯等，如您不感兴趣可通过设置退订或关闭推荐。</p>
        <p class="pb">评估、改善我们的广告投放和其他促销及推广活动的效果。我们可能收集您对广告投放和其他促销及推广活动的反馈信息，并根据您的反馈，帮助我们重新审视、评估并进行改善广告投放和其他促销及推广活动。</p>
        <p class="pb">1.2.2具体使用规则</p>
        <p class="pb">我们会根据本隐私政策的约定并为实现我们的产品或服务功能对所收集的个人信息进行合理使用。</p>
        <p class="pb">在收集您的个人信息后，尤其是个人敏感信息，我们将采用加密等安全措施予以保护，或通过技术手段对信息进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</p>
        <p class="pb">请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并按照法律规定删除/匿名化处理您的个人信息。</p>
        <p class="pb">我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</p>
        <p class="pb">当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</p>
        <p class="pb">为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务。例如，将您在使用我们某项服务时的信息，用于另一项服务中向您展示个性化的内容或广告、用于用户研究分析与统计等服务。</p>
        <p class="pb">为了确保服务安全，帮助我们更好地了解系统的运行情况，我们可能记录相关信息，例如，您使用系统的频率、故障信息、总体使用情况、性能数据以及系统的来源。我们不会将我们存储在分析软件中的信息与您在系统中提供的个人身份信息相结合。</p>
        <p class="pb">当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</p>
        <p class="pb">1.2.3征得授权同意的例外</p>
        <p class="pb">您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意。但我们保证，在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律或相关文件。与国家安全、国防安全直接相关的；与公共安全、公共卫生、重大公共利益直接相关的；与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p class="pb">出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
        <p class="pb">从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；根据个人信息主体要求签订和履行合同所必需的；用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；为合法的新闻报道所必需的；</p>
        <p class="pb">为学术研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；法律法规规定的其他情形。</p>
        <p class="pb">2 我们如何使用Cookie和同类技术</p>
        <p class="pb">2.1 为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的购物偏好或您购物车中的商品等数据进而为您提供购物的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。</p>
        <p class="pb">2.2 为保障产品与服务的安全、高效运转，我们可能会设置认证与保障安全性的Cookie 或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</p>
        <p class="pb">2.3 为您推荐、展示、推送您可能感兴趣的内容或帐号，我们可能会利用此类技术了解您的偏好和使用习惯，进行数据分析，以改善产品服务。</p>
        <p class="pb">2.4 我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问台州停车APP时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</p>
        <p class="pb">3、我们如何共享、转让、公开披露您的个人信息</p>
        <p class="pb">3.1共享</p>
        <p class="pb">3.1.1 共享原则</p>
        <p class="pb">a. 授权同意原则： 未经您的同意，我们不会共享您的个人信息，除非共享的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意。</p>
        <p class="pb">b. 合法正当与最小必要原则： 共享的数据必须具有合法正当目的，且共享的数据以达成目的必要为限。</p>
        <p class="pb">c. 安全审慎原则： 我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。</p>
        <p class="pb">3.1.2 我们不会与台州停车以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：事先获得您明确的同意或授权；现行法律、法律程序的要求、或者公安机关/行政机关/司法等依法提出的共享；我们可能会将您的个人信息与我们的关联方共享。为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护关联方或其他用户或公众的人身财产安全免遭侵害等原因，您的个人信息可能会与我们的关联方共享。我们只会共享必要的个人信息，如果我们改变个人信息的使用及处理目的，将再次征求您的授权同意。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策及其他任何相关的保密和安全措施来处理个人信息。</p>
        <p class="pb">我们可能会向合作伙伴共享您的账户信息以及位置信息，车辆车牌及车型信息，以提供更好的用户服务和用户体验。我们授权的合作伙伴包括以下类型：</p>
        <p class="pb">（a）技术服务的供应商。我们可能会将您的个人信息共享给支持产品/服务功能的第三方，这些支持包括为我们提供基础设施技术服务、支付服务、数据处理等，如我们需要将您的支付金额与第三方支付机构共享以实现其确认支付指令并完成支付等。</p>
        <p class="pb">（b）服务平台或服务提供商。台州停车对接了丰富的第三方服务。为了方便您接受第三方提供的服务，当您选择使用该第三方服务时，您授权我们将您的个人信息提供给第三方服务平台或服务提供商，以便第三方基于相关信息为您提供服务。</p>
        <p class="pb">（c）广告服务类的合作方。我们可能与委托我们进行推广和广告投放的合作伙伴共享信息，但我们不会共享用于识别您个人身份的信息（姓名、身份证号），仅会向这些合作伙伴提供不能识别您个人身份的间接画像标签及去标识化的设备信息或匿名化后的信息，以帮助其在不识别您个人身份的前提下提升广告有效触达率。我们可能与业务的服务商、供应商和其他合作伙伴共享分析去标识化的设备信息或统计信息，这些信息难以或无法与您的真实身份相关联。这些信息将帮助我们分析、衡量广告和相关服务的有效性。</p>
        <p class="pb">（d）我们可能会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的城市热力图或行业洞察报告。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。</p>
        <p class="pb">（e）帮助您参加营销推广活动，当您选择参加我们及我们的关联方或第三方举办的有关营销活动时，可能需要您提供姓名、通信地址、联系方式、银行帐号信息。这些信息是个人敏感信息，拒绝提供可能会影响您参加相关活动，但不会影响其他功能。只有经过您的同意，我们才会将这些信息与关联方或第三方共享，以保障您在联合活动中获得体验一致的服务，或委托第三方及时向您兑现相关服务。</p>
        <p class="pb">3.2转让</p>
        <p class="pb">3.2.1我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：事先获得您明确的同意或授权；在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
        <p class="pb">3.3公开披露</p>
        <p class="pb">3.3.1我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：获得您明确同意；在此我们再次强调，基于法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会向有权机关披露您的个人信息。但我们保证，在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。</p>
        <p class="pb">3.4共享、转让、公开披露个人信息授权同意的例外</p>
        <p class="pb">根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下共享、转让、公开披露您的个人信息：与国家安全、国防安全有关的；与公共安全、公共卫生、重大公共利益有关的;</p>
        <p class="pb">与犯罪侦查、起诉、审判和判决执行等有关的；出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；您自行向社会公众公开的个人信息；从合法公开披露的信息中收集到的个人信息的，如合法的新闻报道、政府信息公开等渠道。根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
        <p class="pb">4、我们如何存储个人信息</p>
        <p class="pb">4.1 存储地点</p>
        <p class="pb">我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。</p>
        <p class="pb">4.2 存储期限</p>
        <p class="pb">4.2.1您的个人信息我们将保存至您账号注销之日，但服务信息、交易信息保存时间为自交易完成之日起三年，但法律、行政法规另有规定的，依照其规定。</p>
        <p class="pb">4.2.2当您的个人信息超出我们所保存的期限后，我们会对您的个人信息进行删除或匿名化处理。</p>
        <p class="pb">4.2.3当您成功申请注销台州停车账户后，我们将对您的个人信息进行删除或匿名化处理。</p>
        <p class="pb">4.2.4如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</p>
        <p class="pb">5、我们如何保护个人信息的安全</p>
        <p class="pb">5.1 我们保护您个人信息的技术与措施</p>
        <p class="pb">5.1.1 数据安全技术措施</p>
        <p class="pb">我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。台州停车的网络服务采取了传输层安全协议等加密技术，确保用户数据在传输过程中的安全。台州停车采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。台州停车采用严格的数据访问权限控制、多重身份认证、数据分类分级技术、规范数据安全开发保护个人信息，避免数据被违规使用。</p>
        <p class="pb">台州停车还将采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</p>
        <p class="pb">5.1.2台州停车为保护个人信息采取的其他安全措施：台州停车通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。台州停车通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。我们仅允许有必要知晓这些信息的台州停车及台州停车关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与台州停车的合作关系。</p>
        <p class="pb">我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或法律要求。</p>
        <p class="pb">5.1.3 安全事件处置</p>
        <p class="pb">我们将尽力确保您发送给我们的任何信息的安全性，为防止安全事故的发生，我们制定了妥善的预警机制和应急预案，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。台州停车也为安全事件建立了专门的应急响应团队。若不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议和对您的补救措施，并立即启动应急预案，力求将损失最小化。我们将及时将事件相关情况以电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。</p>
        <p class="pb">我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况，紧密配合政府机关的工作。</p>
        <p class="pb">5.1.4您的理解与配合</p>
        <p class="pb">我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</p>
        <p class="pb">请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如果您对我们的个人信息保护有任何疑问，可通过本隐私政策最下方约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。</p>
        <p class="pb">6 您如何管理、查看、删除本人的个人信息</p>
        <p class="pb">6.1 台州停车非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利.</p>
        <p class="pb">6.2 除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</p>
        <p class="pb">6.2.1 账户密码：可通过重置密码进行修改，进入“我的车辆”进行修改车辆信息。</p>
        <p class="pb">6.2.2 浏览信息：您可以访问或清除您的搜索历史记录、查看和管理其他数据。</p>
        <p class="pb">6.2.3 对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</p>
        <p class="pb">6.2.4 您无法访问和更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用附加功能时产生的个人信息。上述信息我们会在您授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。</p>
        <p class="pb">6.3 您可以通过删除信息、关闭设备功能、在台州停车平台中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
        <p class="pb">6.4 在以下情形中，您可以向我们提出删除个人信息的请求：如果我们处理个人信息的行为违反相关的法律法规；如果我们收集、使用您的个人信息，却未征得您的同意；如果我们处理个人信息的行为违反了与您的约定或法律的规定；如果您不再使用我们的产品或服务，或者您注销了相关账号；如果我们不再为您提供产品或服务。</p>
        <p class="pb">6.5 获取个人信息副本,您有权获取您的个人信息副本，您可以通过线上联系客服或通过订单反馈联系我们，我们将在三十日内对您的请求进行处理。</p>
        <p class="pb">6.6 响应您的请求</p>
        <p class="pb">6.6.1为了保障安全，对于您的上述请求，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的30天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。对于那些与您的身份不直接关联的信息、无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
        <p class="pb">6.6.2在以下情形中，按照法律法规要求，我们将无法响应您的请求：与国家安全、国防安全直接相关的；与公共安全、公共卫生、重大公共利益直接相关的；与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p class="pb">有充分证据表明您存在主观恶意或滥用权利的；响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；涉及商业秘密的；</p>
        <p class="pb">7、未成年人信息保护</p>
        <p class="pb">7.1 我们的产品/服务仅面向具有完全民事行为能力的成人。如果没有父母/监护人的同意，未成年人不得创建自己的用户账户。</p>
        <p class="pb">7.2对于经父母/监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母/监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
        <p class="pb">7.3如果我们发现自己在未事先获得可证实的父母/监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</p>
        <p class="pb">8、通知和修订</p>
        <p class="pb">8.1为给您提供更好的服务以及随着台州停车业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过台州停车移动端上发出更新版本并在生效前七日通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问台州停车以便及时了解最新的隐私政策。</p>
        <p class="pb">8.2对于重大变更，我们还会提供更为显著的通知（包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容），本政策所指的重大变更包括但不限于：我们的服务模式发生重大变化，如处理个人信息的目的、类型、使用方式等变更；我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有者变更等；个人信息共享、转让或公开披露的主要对象发生变化;</p>
        <p class="pb">您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p class="pb">我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时;</p>
        <p class="pb">个人信息安全影响评估报告表明存在高风险时。</p>
        <p class="pb">9、如何联系我们</p>
        <p class="pb">如果您对个人信息保护问题和本隐私政策有任何疑问、建议、意见，您可以拨打我们客服电话0576-81896000，我们核查并验证您的用户身份后会及时跟您联系。</p>
        <p class="pb">10、其他</p>
        <p class="pb">因本政策以及我们处理您个人信息事宜引起的任何争议，您可诉至有管辖权的人民法院。</p>
        <p class="pb">如果您认为我们的个人信息处理行为损害了您的合法权益，您也可向有关政府部门进行反映。</p>
        <p class="pd">在我们发布本《隐私政策》或向您提供产品或服务均视为本《隐私政策》生效。我们停止运营或永久停止提供产品或服务时本《隐私政策》失效。</p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.privacyaggrement_outbox{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
p{
    margin: 0 auto;
    width: 90%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: justify;
}
.pa{
    margin-top: 20px;
    text-align: center;
}
.pb{
    margin-top: 5px;
    text-indent: 2em;
}
.pc{
    margin-top: 5px;
    text-indent: 2em;
    font-weight: 500;
}
.pd{
    margin-top: 5px;
    margin-bottom: 50px;
    text-indent: 2em;
}
</style>