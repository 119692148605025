<template>
  <div class="park_container_box">
    <!-- 用户 -->
    <div class="park_user">
      <span class="user_span">当前用户：{{ iphone ? iphone : "" }}</span>
      <span class="user_bind" @click="relieve">解绑</span>
    </div>
    <!-- 泊位号 -->
    <div class="berth_box">
      <p class="berth_pa">泊位号：</p>
      <ul class="berth_ul" @click="berthnum">
        <li>{{ berthNum[0] }}</li>
        <li>{{ berthNum[1] }}</li>
        <li>{{ berthNum[2] }}</li>
        <li>{{ berthNum[3] }}</li>
        <li>{{ berthNum[4] }}</li>
        <li>{{ berthNum[5] }}</li>
        <li>
          <nav v-if="isExceed">{{ berthNum[6] }}</nav>
          <nav @click.stop="exceedClick" v-else>+</nav>
        </li>
        <li>
          <nav v-if="isExceed">{{ berthNum[7] }}</nav>
          <nav v-else>8位</nav>
        </li>
      </ul>
    </div>
    <!-- 提交 -->
    <span class="berth_submit_code" @click="submit">提交</span>
    <!-- 键盘 -->
    <div class="keyboard_box" v-if="isShowkey">
      <!-- 左边 -->
      <div class="keyboard_left_box">
        <span @click="keyclick('1')">1</span>
        <span @click="keyclick('2')">2</span>
        <span @click="keyclick('3')">3</span>
        <span @click="keyclick('4')">4</span>
        <span @click="keyclick('5')">5</span>
        <span @click="keyclick('6')">6</span>
        <span @click="keyclick('7')">7</span>
        <span @click="keyclick('8')">8</span>
        <span @click="keyclick('9')">9</span>
      </div>
      <!-- 右边 -->
      <div class="keyboard_right_box">
        <div class="right_span_a" @click="keyclick('0')">0</div>
        <div class="right_delete" @click="keyclick('99')">删除</div>
      </div>
    </div>

    <!-- 弹框 -->
    <div class="enter_marker_box" v-show="isShow">
      <div class="enter_inner_box">
        <span class="inner_attention_title">温馨提示</span>
        <span class="inner_top_span">{{ errMsgtext }}</span>
        <div class="inner_botttom_box">
          <span @click="cancel">返回</span>
          <span @click="goAlipay" v-if="isWeixin=='alipay'">前往充值</span>
          <div v-if="isWeixin=='micromessenger'">
            <wx-open-launch-weapp
              id="launch-btn"
              username="gh_91d425b0b120"
              path="/pages/activitypay/activitypay.html"
              style="
                width: 150px;
                height: 50px;
                text-align: center;
                font-size: 14px;
                color: #000;
                background-color: #fff;
              "
            >
              <script v-is="'script'" type="text/wxtag-template">
                    <style v-is="'style'">
                        .btn{
                            background-color: #fff;
                            color: #000;
                            border: none;
                            width: 150px;
                            height: 50px;
                            text-align: center;
                            line-height: 50px;
                            font-size:14px;
                            border-bottom-right-radius: 10px;
                        }
                    </style>
                <button class="btn">前往充值</button>
              </script>
            </wx-open-launch-weapp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import {
  repeatStopcar,
  getselfCarplateNo,
  selfCurbpakring,
  relievebind,
  getH5WxLoginSign,
} from "@/server/api.js";
import { Toast, Dialog } from "vant";
import wx from "weixin-js-sdk";
export default {
  setup() {
    const isShowkey = ref(true);
    const router = useRouter();
    const allData = reactive({
      berthNum: [],
      isShow: false,
      isShowUser: false,
      isclick: false,
      isExceed: false,
      errMsgtext: "", //弹框提示
      iphone: "",
      isWeixin: 'micromessenger'
    });
    // 键盘的点击事件
    const keyclick = (e) => {
      if (e == "99") {
        allData.berthNum.pop();
        if (allData.isExceed && allData.berthNum.length < 7) {
          allData.isExceed = false;
        }
      } else {
        if (allData.berthNum.length >= (allData.isExceed ? 8 : 6)) {
          return false;
        } else {
          allData.berthNum.push(e);
        }
      }
      console.log(allData.berthNum.join(""));
    };
    //  超出点击 加号 事件
    const exceedClick = () => {
      allData.isExceed = true;
    };
    onMounted(() => {
      allData.isWeixin = isWeixin()
      // 微信公众号获取签名
      allData.pathurl = location.href.split("#")[0];
      getH5WxLoginSign({ url: allData.pathurl }).then((res) => {
        console.log(res);
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识 gh_298d2d1bf9ee   wx3d385eb9ae7f6482
          timestamp: res.data.timestamp.toString(), // 必填，生成签名的时间戳
          nonceStr: res.data.noncestr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            "openTagList",
            "onMenuShareTimeline",
            "onMenuShareAppMessage",
            "onMenuShareQQ",
            "onMenuShareQZone",
            "hideOptionMenu",
          ], //必填，需要使用的JS接口列表1
          openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        });
        // 处理成功验证
        wx.ready(function () {
          console.log("wx.ready-success");
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
        });
        // 处理失败验证
        wx.error(function (res) {
          console.log(res);
          console.log("error fail");
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        });

        var btn = document.getElementById("launch-btn");
        btn.addEventListener("launch", function (e) {
          allData.isShow = false;
          console.log("success", e);
        });
        btn.addEventListener("error", function (e) {
          console.log("fail", e.detail);
        });
      });

      // console.log(localStorage.getItem('isbind'))
      // if(localStorage.getItem('isbind')){
      //     if(localStorage.getItem('isbind') == false){
      //         allData.isShowUser = false
      //     }else if(localStorage.getItem('isbind') == true) {
      //         allData.isShowUser = true
      //     }
      // }

      allData.iphone = localStorage.getItem("taihophne");

      var centerBerthnum = localStorage.getItem("berthCode")
        ? localStorage.getItem("berthCode")
        : ""; // 这是泊位号
      // var centerBerthnum = '030006'; // 这是泊位号
      console.log(centerBerthnum.length,'centerBerthnum.length');
      if (centerBerthnum.length > 6) {
        allData.isExceed = true;
      } else {
        allData.isExceed = false;
      }
      console.log(centerBerthnum.split(""),'centerBerthnum.split("")');
      allData.berthNum = centerBerthnum.split("");
    });
    const berthnum = () => {
      isShowkey.value = !isShowkey.value;
    };
    // 点击提交
    const submit = () => {
      // router.push('/parkdetail')
      if (allData.berthNum.length != (allData.isExceed ? 8 : 6)) {
        Toast("请检查泊位号是否正确");
        return false;
      } else {
        const berthnum = allData.berthNum.slice(",").join("");
        if (allData.isclick) return false;
        allData.isclick = true;
        parking(berthnum);
      }
    };
    const parking = (berthnum) => {
      console.log(berthnum);
      // 判断车辆是否重复停靠
      repeatStopcar({ berthCode: berthnum }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          if (res.data == null) {
            // code 为 200，并且 data == null
            // 判断当前泊位号是否有车牌
            getselfCarplateNo({ berthCode: berthnum }).then((res) => {
              if (res.code == 200) {
                // 1 无 2 有
                if (res.data.plateNo == null && res.data.isRegister == 1) {
                  getParknum(berthnum);
                } else if (
                  res.data.plateNo == null &&
                  res.data.isRegister == 2
                ) {
                  Dialog.alert({
                    title: "温馨提示",
                    message: "该泊位已经被其他用户占用，无法再次拨号",
                  }).then(() => {
                    // on close
                    allData.isclick = false;
                  });
                } else if (res.data.plateNo && res.data.isRegister == 1) {
                  Dialog.confirm({
                    title: "温馨提示",
                    message:
                      "该泊位已登记了车辆" + res.data.plateNo + "是您的车吗？",
                  })
                    .then(() => {
                      // on confirm
                      getParknum(berthnum);
                    })
                    .catch(() => {
                      // on cancel
                      console.log("点击了取消");
                      allData.isclick = false;
                    });
                } else if (res.data.plateNo && res.data.isRegister == 2) {
                  Dialog.confirm({
                    title: "温馨提示",
                    message: "该泊位已被其他用户占用，无法再次拨号",
                  })
                    .then(() => {
                      // on confirm
                      console.log("00");
                      allData.isclick = false;
                    })
                    .catch(() => {
                      // on cancel
                      console.log("点击了取消");
                      allData.isclick = false;
                    });
                }
              } else {
                Toast(res.msg);
                allData.isclick = false;
              }
            });
          } else {
            // code 为 200，并且data 不为 null
            Toast.loading({
              message: "数据提交中...",
              forbidClick: true,
            });
            allData.isclick = false;
            setTimeout(() => {
              router.push({
                path: "/parkdetail",
                query: {
                  bert: berthnum,
                },
              });
            }, 1500);
          }
          localStorage.removeItem("berthCode");
        } else {
          Toast(res.msg);
          allData.isclick = false;
          localStorage.removeItem("berthCode");
        }
      });
    };
    const getParknum = (berthnum) => {
      selfCurbpakring({ berthCode: berthnum }).then((res) => {
        if (res.code == 200) {
          Toast.loading({
            message: "数据提交中...",
            forbidClick: true,
          });
          allData.isclick = false;
          setTimeout(() => {
            router.push({
              path: "/parkdetail",
              query: {
                bert: berthnum,
              },
            });
          }, 1500);
        } else {
          allData.isclick = false;
          if (res.msg.includes("充值")) {
            allData.errMsgtext = "";
            allData.errMsgtext = res.msg + ",或者在离场时扫泊位码可以直接支付";
            console.log("里面充值了");
            allData.isShow = true; // 显示弹框
          } else {
            Toast(res.msg);
          }
        }
      });
    };
    const markershow = () => {
      allData.isShow = true;
    };
    const cancel = () => {
      allData.isShow = false;
    };
    // 解绑
    const relieve = () => {
      relievebind({ openid: localStorage.getItem("openid") }).then((res) => {
        console.log(res);
        localStorage.removeItem("isbind");
        localStorage.removeItem("taiht");
        localStorage.removeItem("loginTime");
        localStorage.removeItem("taihu");
        localStorage.removeItem("taihophne");
        router.push({
          path: "/logon",
          replace: true,
        });
      });
    };
    // 判断当前浏览器是不是微信浏览器
    const isWeixin = () => {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return "micromessenger";
      } else if (ua.match(/Alipay/i) == "alipay") {
        return "alipay";
      } else {
        return false;
      }
    };
    const goAlipay = () =>{
      window.location.href = "alipays://platformapi/startapp?appId=2021002170682296&page=pages/activitypay/activitypay"
    }
    return {
      ...toRefs(allData),
      keyclick,
      berthnum,
      isShowkey,
      submit,
      markershow,
      cancel,
      relieve,
      exceedClick,
      goAlipay
    };
  },
};
</script>


<style scoped>
.park_container_box {
  width: 100vw;
  height: 100vh;
  background: #f6f7f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 停车用户 */
.park_user {
  width: 92%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.user_span {
  font-size: 14px;
}
.user_bind {
  margin-left: 20px;
  padding: 2px 10px;
  border-radius: 4px;
  background-image: linear-gradient(51deg, #1991a3 0%, #7cb630 100%);
  color: #fff;
  text-align: center;
  font-size: 14px;
}
/* 泊位号 */
.berth_box {
  width: 92%;
  display: flex;
  flex-direction: column;
}
.berth_pa {
  margin-top: 20px;
  width: 100%;
  text-align: left;
  font-size: 16px;
}
.berth_ul {
  margin-top: 20px;
  width: 100%;
  height: 36px;
  /* background: pink; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
li {
  list-style: none;
}
.berth_ul > li {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  text-align: center;
  line-height: 36px;
}

/* 自定义键盘 */
.keyboard_box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 220px;
  box-shadow: 0px -10px 14px #f2f2f2;
  display: flex;
}
/* 左边 */
.keyboard_left_box {
  padding: 0 10px 0px 20px;
  width: 70%;
  height: 100%;
  /* background: skyblue; */
  flex-wrap: wrap;
}
.keyboard_left_box > span {
  margin-top: 24px;
  display: inline-block;
  width: 70px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #e8e9ec;
  font-size: 18px;
}
.keyboard_left_box > span:nth-child(2) {
  margin: 0 15px;
}
.keyboard_left_box > span:nth-child(5) {
  margin: 0 15px;
}
.keyboard_left_box > span:nth-child(8) {
  margin: 0 15px;
}

/* 右边 */
.keyboard_right_box {
  width: 30%;
  height: 100%;
  /* background: #7CB630; */
}
.right_span_a {
  margin: 0 auto;
  margin-top: 24px;
  width: 60px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #e8e9ec;
  font-size: 22px;
}
.right_delete {
  margin: 0 auto;
  margin-top: 28px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: linear-gradient(50deg, #1991a3 5%, #7cb630 100%);
  border-radius: 5px;
  box-shadow: 0px 5px 4px #e8e9ec;
  color: #fff;
  font-size: 16px;
}

/* 提交 */
.berth_submit_code {
  margin-top: 30px;
  width: 92%;
  height: 48px;
  background-image: linear-gradient(51deg, #1991a3 0%, #7cb630 100%);
  border-radius: 24px;
  line-height: 48px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

/* 弹框 */
.enter_marker_box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.enter_inner_box {
  width: 80%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.inner_attention_title {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
}
.inner_top_span {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.inner_botttom_box {
  border-top: 1px solid #e8e9ec;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inner_botttom_box > span {
  width: 50%;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
</style>