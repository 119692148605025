import { createRouter, createWebHashHistory } from "vue-router"
// createWebHashHistory   哈希模式
// createWebHistory history 模式

const LogOn = () => import('@/view/logon.vue') //登录页
const Agreement = () => import('@/view/agreement.vue') //用户协议
const Privacy = () => import('@/view/privacy.vue') //隐私政策
const Parking =() => import('@/view/parking.vue') //泊位停车
const ParkDetail =()=>import('@/view/parkdetail.vue') //泊位停车订单详情
const Entrance = () => import('@/view/entrance.vue') //过渡页面
const settlement = () => import('@/view/settlement.vue') //订单详情页面
const codeStart = () => import('@/view/codeStart.vue') //小票二维码进入后的中间页
const routes = [
    {
        path:'/',
        name:"entrance",
        component:Entrance,
    },
    {
        path: '/logon',
        name: 'logon',
        component: LogOn
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: Agreement
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
    },
    {
        path:'/parking',
        name:'parking',
        component:Parking
    },
    {
        path:'/parkdetail',
        name:'ParkDetail',
        component:ParkDetail
    },
    {
        path:'/settlement',
        name:'settlement',
        component:settlement
    },
    {
        path:'/codeStart',
        name:'codeStart',
        component:codeStart
    },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})