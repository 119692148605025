import { createApp } from 'vue'
import App from './App.vue'
import {router} from '@/router/index.js';
import common from '@/assets/css/common.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Swipe, SwipeItem,Checkbox} from 'vant';


// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();


const app = createApp()
app.config.isCustomElement = tag => ['wx-open-launch-app'].includes(tag)

const myapp = createApp(App)
myapp.use(router)
myapp.use(common)
myapp.use(Vant)
myapp.use(Swipe,SwipeItem,Checkbox )
myapp.mount("#app");
