<template>
  <div class="entrance_box">
    <img class="entrance_image" src="@/assets/entrance.png" alt />
    <div></div>
  </div>
</template>  

<script>
import { onMounted, reactive, toRefs } from "vue";
// import wx from "weixin-js-sdk";
import { useRouter } from "vue-router";
import { codeInformation, getH5WxConfig, ongoingOrder } from "@/server/api.js";
// import { getH5WxLoginSign,  } from "@/server/api.js";
import { Toast } from "vant";
// import VConsole from 'vconsole';
export default {
  setup() {
    const allData = reactive({
      pathurl: "",
    });
    const router = useRouter();
    onMounted(() => {
      var pageUrl = window.location.href;
      // 不是测试环境且url存在wxnew时跳转到正确的网址上
      if (process.env.NODE_ENV!='development' && pageUrl.includes("wxnew")) {
        window.location.href = "https://wxscan.tzzntc.com/?"+pageUrl.split("?")[1].split("#")[0]
      }
      // 测试环境打开debug工具
      // if (process.env.NODE_ENV=='development' || window.location.href.includes('testwx')) {
      //   new VConsole()
      // }
      // 判断有无订单
      var historyUrl = localStorage.getItem("durpUrlcenter")||''
      //路径里带了（parktype或缓存里带了parktype）以及路径里不带berthCode   roadParkFee
      // console.log((pageUrl.includes("roadParkFee") || historyUrl.includes("roadParkFee")) && !pageUrl.includes("berthCode"));
      if ((pageUrl.includes("roadParkFee") || historyUrl.includes("roadParkFee")) && !pageUrl.includes("berthCode")) {
        //扫管理员小票进入
        if (pageUrl.includes("roadParkFee")) {
          codeStart(pageUrl)
        } else {
          codeStart(historyUrl)
        }
      }else {
        // 路边泊位号进入
        parkdetail()
      }
      
    });
    //扫管理员小票进入
    const codeStart = (pageUrl) => {
      // 小票二维码进入时存储第一次进来时的网址信息
      localStorage.setItem("durpUrlcenter", pageUrl); //存储第一次进来的url地址
      //判断当前环境
      allData.environment = isWeixin()
      if (!allData.environment) {
        //测试环境不拦截
        if (process.env.NODE_ENV=='development' || window.location.href.includes('testwx')) {
          router.replace({
            name: "codeStart"
          });
        } else {
          Toast("请在微信或支付宝环境下打开此页面");
          return false;
        }
        
      }else if(allData.environment=='micromessenger'){
        // 微信环境
        // 判断有无code=》有的话就跳到codeStart，无就调接口，然后跳转
        var urlParameter = getUrl(window.location.href);
        // 测试环境或者有code就不跳转到微信
        // if(urlParameter.code || process.env.NODE_ENV=='development'|| window.location.href.includes("test")){
        if(urlParameter.code || process.env.NODE_ENV=='development'){
          router.replace({
            name: "codeStart"
          });
        } else {

          // 微信公众号授权获取 code
          getH5WxConfig({}).then((res) => {
            // Toast(JSON.stringify(res))
            // console.log(res);
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+res.data.user_APPID+'&redirect_uri='+res.data.redirectUri+'&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
          });
        }
        // return
      }else if(allData.environment=="alipay"){
        // 支付宝环境
        router.replace({
          name: "codeStart"
        });
        // return
      }
    }
    // 路边扫码进入的方法
    const parkdetail = () => {
      var pageUrl = window.location.href;
      var urlParameter = getUrl(pageUrl);
      console.log(pageUrl,urlParameter);
      var form={}
      if (urlParameter.berthCode) {
        form = { entryNum: urlParameter.berthCode.split("#")[0] };
        localStorage.setItem('berthCode', urlParameter.berthCode.split("#")[0])
      } else {
        form={entryNum:localStorage.getItem('berthCode')}
      }
      // console.log(form);
      //根据泊位获取有无订单
      ongoingOrder(form).then((res) => {
        if (res.data.billId) {
          router.replace({
            name: "settlement",
            query: {
              data: JSON.stringify(res.data)
            }
          });
        } else {
          // 判断当前是否是在微信浏览器下面
          if (isWeixin() == "micromessenger" || isWeixin() == "alipay" || window.location.href.includes('testwx') || process.env.NODE_ENV=='development') {
            //   var pageUrl = 'https://wxscan.tzzntc.com/?code=091TU0000xs9JN1eUO300xt2og1TU00m&state=123#/'
            // var pageUrl = window.location.href;
            // var urlParameter = getUrl(pageUrl);
            // console.log(urlParameter);
            if (urlParameter.code || isWeixin() == "alipay" || window.location.href.includes('testwx') || process.env.NODE_ENV=='development') {
              // 存在 code 的情况
              //   通过url获取泊位号
              // var centerBerthUrl = localStorage.getItem('durpUrl')
              // getBerthByQrcode({qrcodeUrl:centerBerthUrl}).then(res=>{
              //     console.log(res)
              //     if(res.data.code == 200){
              //         var arr = [];
              //         for (var j = 0; j < res.data.data.length; j++) {
              //             arr.push(res.data.data[j])
              //         }
              //         localStorage.setItem('berthCodeNum',arr)
              //         localStorage.removeItem('durpUrl')
              //     }
              // })
              let taihu = localStorage.getItem('taihu')
              // 判断有没有登录过
              if (taihu&&taihu.length>10) {
                let loginTime = new Date(localStorage.getItem('loginTime')).getTime()
                let currentTime = new Date().getTime()
                // 判断token有无过期
                if((currentTime-loginTime)/1000/60/60/24>=7) {
                  router.replace({
                    path: "/logon",
                    query: {
                      openid: res.data.openid,
                    },
                  });
                } else {
                  router.replace({
                    path: "/parking",
                  });
                }
                return false
              }
              codeInformation({ code: (urlParameter.code||'1') }).then((res) => {
                console.log(res);
                if (res.code == 200) {
                  localStorage.setItem("openid", res.data.openid);
                  if (res.data.isBinding == true) {
                    // 已经绑定的,直接去停车页面
                    localStorage.setItem("isbind", res.data.isBinding);
                    localStorage.setItem("taiht", res.data.token.accessToken);
                    localStorage.setItem("taihophne", res.data.token.phone);
                    setTimeout(() => {
                      router.replace({
                        path: "/parking",
                      });
                    }, 500);
                  } else if (res.data.isBinding == false) {
                    //没有绑定的，跳转去登录页面
                    localStorage.setItem("isbind", res.data.isBinding);
                    setTimeout(() => {
                      router.replace({
                        path: "/logon",
                        query: {
                          openid: res.data.openid,
                        },
                      });
                    }, 500);
                  }
                }
              });
            } else {
              //没有存在code，请求接口，跳转授权
              // https://wxscan.tzzntc.com/?berthCode=111111
              localStorage.setItem("durpUrlcenter", location.href); //存储第一次进来的url地址
              var centerCodeURL = getUrl(location.href);
              console.log(location.href,centerCodeURL);
              if (centerCodeURL.berthCode) {
                var loCode = centerCodeURL.berthCode;
                localStorage.setItem(
                  "berthCodeNum",
                  loCode.includes("#") ? loCode.split("#")[0] : loCode
                ); //存储第一次进来的url地址
              }
              // 微信公众号授权获取 code
              getH5WxConfig({}).then((res) => {
                console.log("泊位号跳转");
                // let url =
                //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                //   res.data.user_APPID +
                //   "&redirect_uri=" +
                //   res.data.redirectUri +
                //   "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
                // console.log(url);
                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+res.data.user_APPID+'&redirect_uri='+res.data.redirectUri+'&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
              });
            }
          } else {
            Toast("请在微信环境下打开此页面");
          }
        }
      });
    }
    // 获取url路径中?后面的参数
    const getUrl = (urlStr) => {
      var url;
      if (typeof urlStr == "undefined") {
        url = decodeURI(location.search); //获取url中"?"符后的字符串
      } else {
        url = "?" + urlStr.split("?")[1];
      }
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
          19;
        }
      }
      return theRequest;
    };

    // 判断当前浏览器是不是微信浏览器
    const isWeixin = () => {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return "micromessenger";
      } else if (ua.match(/Alipay/i) == "alipay") {
        return "alipay";
      } else {
        return false;
      }
    };
    return {
      ...toRefs(allData),
    };
  },
};
</script>

<style scoped>
.entrance_box {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.entrance_image {
  margin-top: 20vh;
  width: 80%;
  height: 180px;
}
</style>