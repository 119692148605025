<template>
  <div class="logo_container_box">
    <img class="logo_image" src="@/assets/offlogo.png" alt="">
    <!-- 登录输入框 -->
    <div class="logo_box">
      <div class="logo_item_box">
        <span class="item_span_a">手机号码</span>
        <input class="item_input" ref="iphone" type="number" @input="inputGetCode" placeholder="请填写手机号码" >
        <span class="item_span_b" @click="getcode" >{{codetext}}</span>
      </div>
      <div class="logo_item_box">
        <span class="item_span_a">短信验证码</span>
        <input class="item_input" ref="iphonecode" maxlength="6" type="text" placeholder="请填写短信验证码">
        <span class="item_span_b"></span>
      </div>
    </div>
    <!-- 立即绑定 -->
    <span class="binding" @click="bind">扫码功能授权</span>
    <!-- 用户协议和隐私政策 -->
    <div class="agreement_text">
      <van-checkbox icon-size="16" class="vancheckobx" checked-color="#1991A3" v-model="checked"></van-checkbox>
      <span class="agreement_span">我已经阅读并同意<em @click="agreement">《用户协议》</em>和<em @click="privacy">《隐私政策》</em>了解详细信息</span>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from 'vue';
import { Toast } from 'vant'
import { useRouter} from 'vue-router'
import { getiphonecode, logonWeb } from '@/server/api.js'
export default {
  setup(){
    const checked = ref(false);
    const router = useRouter();
    // const routes = useRoute();
    var iphone = ref(null)
    var iphonecode = ref(null)
    var allData = reactive({
      codetext:'获取验证码',
      isClickcode:false,
      openID:null,
    })

    
    const getcode = () =>{
      var myreg = /^1[3456789]\d{9}$/;
      if (!(myreg.test(iphone.value.value))) {
        Toast('手机号码错误')
        return false;
      }
      localStorage.setItem('taihophne',iphone.value.value)
      var timer = null;
      var time = 60;
      if(!iphone.value.value){
        Toast('手机号不能为空')
      }else{
        if(allData.isClickcode){
          Toast('请勿重复点击')
          return false
        }
        allData.isClickcode = true
        timer = setInterval(() => {
          if(time <= 1){
            allData.codetext = '重新获取'
            allData.isClickcode = false
            clearInterval(timer)
            return false
          }
          --time
          allData.codetext = time +'秒' 
        }, 1000);
        // 通过手机号码获取code
        getiphonecode({phone:iphone.value.value}).then(res=>{
          console.log(res)
          if(res.code == 500){
            Toast(res.msg)
          }else {
            Toast(res.msg)
          }
        })

      }
    }
    // 立即绑定事件
    const bind = () => {
      console.log(checked.value)
      if(checked.value){
        localStorage.removeItem('iphone')
        localStorage.removeItem('iphonecode')
        console.log('勾选了')
      }else{
        Toast('请先阅读并同意协议')
        return false
      }

      if(iphone.value.value == '' || iphonecode.value.value == ""){
        Toast('数据不能为空')
        return false
      }else {
        // 登录
        localStorage.removeItem('taiht')
        console.log()
        logonWeb({phone:iphone.value.value,verifyCode:iphonecode.value.value,openId:localStorage.getItem('openid')}).then(res=>{
          console.log(res)
          if(res.code == 500){
            Toast(res.msg)
          }else if(res.code == 200){
            Toast('授权成功')
            localStorage.setItem('taihu', JSON.stringify(res.data))
            localStorage.setItem('taiht', res.data.accessToken)
            localStorage.setItem('loginTime', (new Date()))
            setTimeout(() => {
              router.replace('/parking')
            }, 1000);
          }
        })
      }



    }
    // 用户协议
    const agreement = () => {
      localStorage.setItem('iphone',iphone.value.value)
      localStorage.setItem('iphonecode',iphonecode.value.value)
      router.push('/agreement')
    }
    // 隐私政策
    const privacy = () => {
      localStorage.setItem('iphone',iphone.value.value)
      localStorage.setItem('iphonecode',iphonecode.value.value)
      router.push('/privacy')
    }
    // 手机号输入到11位时自动获取验证码
    const inputGetCode = (e) => {
      if (e.srcElement.value.length==11) {
        getcode()
      }
    }
    onMounted(()=>{
      if(localStorage.getItem('iphone')){
        iphone.value.value = localStorage.getItem('iphone')
      }
      if(localStorage.getItem('iphonecode')){
        iphonecode.value.value = localStorage.getItem('iphonecode')
      }
    })
    return{
      ...toRefs(allData),
      checked,
      getcode,
      iphone,
      bind,
      agreement,
      privacy,
      inputGetCode,
      iphonecode
    }
  }
}
</script>


<style scoped>
.logo_container_box{
  width: 100vw;
  height: 100vh;
  background: #f3f6f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo_image{
  width: 134px;
  height: 51px;
  margin-top: 26px;
}
/* 登录输入框 */
.logo_box{
  width: 100%;
  background: #fff;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo_item_box{
  width: 91.47%;
  height: 56px;
  border-bottom: 1px solid #e8e9ec;
  display: flex;
  align-items: center;
}
.item_span_a{
  width: 92px;
  height: 22px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 16px;
  color: #363A44;
  text-align: left;
  border-right: 1px solid #D8D8D8;
}
.item_input{
  margin-left: 13px;
  width: 40%;
  height: 22px;
  border: none;
  outline: none;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  /* color: #B3B5B9; */
}
.item_span_b{
  width: 100px;
  height: 22px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #1991A3;
  text-align: center;
}
/* 立即绑定 */
.binding{
  margin-top: 36px;
  width: 91.47%;
  height: 48px;
  background-image: linear-gradient(51deg, #1991A3 0%, #7CB630 100%);
  border-radius: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  line-height: 48px;
}


/* 用户协议和政策 */
.agreement_text{
  margin-top: 20px;
  width: 91.47%;
  display: flex;
  align-items: flex-start;
}
.vancheckobx{
  width: 18px;
  height: 18px;
}
.agreement_span{
  margin-left: 5px;
  font-size: 14px;
  text-align: justify;
}
.agreement_span>em{
  font-style: normal;
  color: #1991A3;
}







</style>
