<template>
    <div class="user_agreement_box">
        <p class="pa">用户协议</p>
        <p class="pa">引言</p>
        <p class="pb">台州停车（我们）非常重视用户您的隐私和个人信息保护。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过《台州停车用户协议》向您说明，在您使用我们的服务时，我们如何收集、使用、储存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。本《使用协议》与您所使用台州停车服务息息相关，希望您仔细阅读，在需要时，按照本《使用协议》的指引，作出您认为适当的选择。</p>
        <p class="pb">本使用协议将帮助您了解以下内容：</p>
        <p class="pb">1.服务条款的确认</p>
        <p class="pb">2.用户的账号、密码及安全性</p>
        <p class="pb">3.服务内容</p>
        <p class="pb">4.服务变更、中断或终止</p>
        <p class="pb">5.使用规则</p>
        <p class="pb">6.免责声明</p>
        <p class="pb">7.知识产权</p>
        <p class="pb">8.通知送达</p>
        <p class="pb">9.独立性</p>
        <p class="pb">10.完整性</p>
        <p class="pb">11.法律的适用和管辖</p>
        <p class="pb">请特别注意：免除或者限制台州停车责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，以及开通或使用某项服务的单独协议。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。</p>
        <p class="pb">一、服务条款的确认</p>
        <p class="pb">请务必认真阅读和理解本《使用协议》（以下简称《协议》）中规定的所有权利和限制。除非您接受本《协议》条款，否则您无权注册、登陆或使用本协议所涉及的相关服务。您一旦注册、登陆、使用或通过任何方式使用本《协议》所涉及的相关服务的行为将视为对本《协议》的接受，即表示您同意接受本《协议》各项条款的约束。如果您不同意接受本《协议》中的条款，请不要注册、登陆或使用本《协议》的相关服务。</p>
        <p class="pb">二、用户的账号、密码及安全性</p>
        <p class="pb">您一旦注册成为我们的用户，您将得到一个账号及对应验证码而设置的密码。如果因为您未保管好自己的账号密码而对您、台州停车或第三方造成的损害，您将负全部责任。此外，您将对自身账户中所有活动和事件负全责。您可以随时改变您的密码，也可以结束旧账户重开新账户。您若发现任何非法使用账户行为和安全漏洞的行为，请立即告知台州停车。</p>
        <p class="pb">三、服务内容</p>
        <p class="pb">台州停车手机客户端的所有权和运作权归台州市智能停车管理有限公司所有。所提供的服务必须按照其发布的服务条款、隐私政策和操作规则等严格执行。您的手机客户端可以在线查找空余车位、充值、缴费、查询停车场信息、对提供之服务发表意见等。除非本协议另有规定，台州停车对网络服务不承担任何责任，即用户对网络服务的使用承担风险。台州停车不保证服务一定满足用户使用要求，也不保证服务不会受中断，对服务的及时性、安全性、准确性也不作担保。</p>
        <p class="pb">四、服务变更、中断或终止</p>
        <p class="pb">以下情况台州停车有权在告知用户后中断或终止对用户提供本协议所描述的网络服务而无需对用户或任何第三方承担责任：</p>
        <p class="pb">1、用户提供的个人资料不真实</p>
        <p class="pb">2、用户违反本协议中规定的使用规则</p>
        <p class="pb">3、用户其他违反法律之行为</p>
        <p class="pb">同时，台州停车保留依据自主判断在将来任何时间变更、修改、增加和删除本服务协议条款的权利。所有修改的协议均构成本协议的组成部分。台州停车有权随时对协议条款进行修改，一旦协议内容发生变动，台州停车将会在官方网站或APP公布修改之后的协议内容，该公布行为视为台州停车已经通知用户修改内容。用户使用协议所提供之服务时应接受协议相关的规则和说明，且此规则和说明均构成本服务条款的一部分。如用户不接受协议所涉及的规则及相关说明，可以主动取消已获得的服务。</p>
        <p class="pb">五、使用规则</p>
        <p class="pb">1、用户应遵守中华人民共和国有关法律、法规，特别是《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》等计算机和互联网的法律法规和实施办法，用户须承担一切因用户的行为直接或间接引起的法律责任。在任何情况下，台州停车认为用户的行为可能违反国家法律、法规，可以在任何时候不经事先通知终止向该用户提供服务。</p>
        <p class="pb">2、用户必须提供准确个人资料，如资料有任何变动，必须在三个工作日内更新。</p>
        <p class="pb">1)用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用或存在其它安全问题等情况，应立即通知台州停车客服。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，台州停车不承担任何责任。如用户违反相关法律法规和本协议各项规定，台州停车有权不经通知删除该帐号，并停止为该用户提供相关网络服务。</p>
        <p class="pb">2)用户注册成功后，视为允许台州停车通过邮件或其他方式向用户发送相关优惠服务信息。</p>
        <p class="pb">六、免责声明</p>
        <p class="pb">1、本服务涉及到互联网及移动通讯等服务，可能会受到各个环节不稳定因素的影响。因此服务存在不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、GSM网络、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。使用本服务的用户须承担以上风险，台州停车对服务之及时性、安全性、准确性不作担保，对因此导致用户不能发送和接受信息、或传递错误，个人设定之时效、未予储存或其它问题不承担任何责任。对于不可抗力或台州停车过错原因导致的用户数据损失、丢失或服务停止，台州停车将不承担任何责任。</p>
        <p class="pb">2、台州停车无法对用户的使用行为进行全面控制，因此对用户不作任何类型的担保。用户同意台州停车的使用承担全部风险。用户使用经由本服务下载的或取得的任何资料、信息而导致该用户系统损坏或资料流失等风险自行负担。</p>
        <p class="pb">3、台州停车客户端内设有通往其他网站和网页的链接，但这些网站和网页并非台州停车经营或控制，台州停车对其正确性、合法性、正当性不负任何责任。用户启动任何此类链接或网页，所有风险自负；因使用或依赖上述网站或资源所产生的损失或损害，台州停车不承担任何责任。</p>
        <p class="pb">4、用户同意保障和维护台州停车及其他用户的利益，如用户使用该服务导致台州停车或任何其他第三人造成损失等，用户同意承担一切责任。台州停车对用户使用网络服务所产生的任何直接、间接、偶然、特殊及继起的损害不负任何责任。</p>
        <p class="pb">5、任何单位或个人认为台州停车的内容可能涉嫌侵犯其合法权益，应该及时向台州停车书面反馈，并提供有效身份证明、权属证明及详细侵权情况证明，在收到上述法律文件后，台州停车将会尽快移除被控侵权内容。</p>
        <p class="pb">七、知识产权</p>
        <p class="pb">台州停车提供的服务中包含的任何文本、图片、图形、音频和视频资资料均受版权、商标、和其他财产所有权法律的保护，未经相关权利人的同意，上述资料均不得在任何媒体直接或者间接发布、播放、出于播放或发布目的而改写或者在发行，或者被用于其他任何商业目的。所有这些资料或者资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。台州停车位提供服务而使用的任何软件(包括但不限于软件中所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料)的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程、反向编译或反汇编。</p>
        <p class="pb">八、通知送达</p>
        <p class="pb">台州停车向用户发出的通知或提示，可以采用电子邮件、手机短信、网页公告或台州停车认为适合的其他形式发布；该等通知的发布之日即视为已送达用户。</p>
        <p class="pb">九、独立性</p>
        <p class="pb">1、本协议中的某些条款因故无法适用，则本协议的其他条款继续适用且无法适用的条款将会被修改，以便其能够依法适用。</p>
        <p class="pb">2、每部分的题目只为阅读之便而无任何法律或合同义务。</p>
        <p class="pb">十、完整性</p>
        <p class="pb">本协议是用户和本产品之间关于本产品相关事项的最终的、完整的、排他的协议，且取代之前当事人关于此类事项（包括之前的用户许可、服务条款等）的讨论和协议。</p>
        <p class="pb">十一、法律的适用和管辖</p>
        <p class="pd">本服务协议的生效、履行、解释及争议的解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。发生争议时如不能协商解决，则应向台州停车所在地人民法院提起诉讼。</p>
    </div>
</template>


<script>
export default {
    
}
</script>

<style scoped>
.user_agreement_box{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
p{
    margin: 0 auto;
    width: 90%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: justify;
}
.pa{
    margin-top: 20px;
    text-align: center;
}
.pb{
    margin-top: 5px;
    text-indent: 2em;
}
.pc{
    margin-top: 5px;
    text-indent: 2em;
    font-weight: 500;
}
.pd{
    margin-top: 5px;
    margin-bottom: 50px;
    text-indent: 2em;
}
</style>