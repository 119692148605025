import axios from 'axios'
import qs from 'qs'
import url from '@/server/url.js'

class HttpRequest {
    constructor(options){
        this.defaults={
            baseUrl:''
        }
        this.defaults = Object.assign(this.defaults, options)
    }

    interceptors(install){
        // 请求方向的拦截器
        install.interceptors.request.use(
            config=>{
                config.headers['sysCode'] = "H5_WX_MINI"
                if(localStorage.getItem('taiht')){
                    config.headers['token'] = localStorage.getItem('taiht')
                }
                //
                // config.headers['Content-Type'] = 'application/json'
                return config;  //放行请求
            },
            err=>{
                return Promise.reject(err.response);
            }
        );
        // 响应方向的拦截器
        install.interceptors.response.use(
            res=>{
                const {data, status} = res;   // eslint-disable-line no-unused-vars
                return data
            },
            err=>{
                return Promise.reject(err.response);
            }
        )
    }

    request(options){
        options = Object.assign(this.defaults,options);
        const instance = axios.create(options);
        this.interceptors(instance);
        return instance
    }
}


// 实例化
const request = new HttpRequest({baseUrl:'/'});
const http = request.request();


// 获取手机验证码 
export async function getiphonecode(params){
    return await http.post(url.getiphonecode, qs.stringify(params))
}

// 通过code 获取用户信息
export async function codeInformation(params){
    return await http.post(url.codeInformation, qs.stringify(params))
}

// 登录
export async function logonWeb(params){
    return await http.post(url.logonWeb, params)
}

// 结束用户绑定
export async function relievebind(params){
    return await http.post(url.relievebind, qs.stringify(params))
}

// 判断车辆是否重复停靠
export async function repeatStopcar(params){
    return await http.post(url.repeatStopcar, qs.stringify(params))
}

// 获取个人路内停车停车的车牌号
export async function getselfCarplateNo(params){
    return await http.post(url.getselfCarplateNo, qs.stringify(params))
}

// 个人路内停车
export async function selfCurbpakring(params){
    return await http.post(url.selfCurbpakring, qs.stringify(params))
}

// 获取个人路内停车泊位号订单详情
export async function getberthOrderdetail(params){
    return await http.post(url.getberthOrderdetail, qs.stringify(params))
}

// 微信公众号h5授权获取code
export async function getH5WxConfig(params){
    return await http.post(url.getH5WxConfig, qs.stringify(params))
}

// 微信公众号签名
export async function getH5WxLoginSign(params){
    return await http.post(url.getH5WxLoginSign, qs.stringify(params))
}

// 通过url获取泊位号
export async function getBerthByQrcode(params){
    return await http.post(url.getBerthByQrcode, qs.stringify(params))
}

// 用户拨号完成后提示用户绑定车牌
export async function queryPlateNoByUid(params){
    return await http.post(url.queryPlateNoByUid,qs.stringify(params))
}

// 根据泊位获取订单信息
export async function ongoingOrder(params){
    return await http.post(url.ongoingOrder,qs.stringify(params))
}

//createUserPayLink
export async function createUserPayLink(params){
    return await http.post(url.createUserPayLink,params)
}

// 小票直接支付接口
export async function createUserPayLinkDirectPay(params){
    return await http.post(url.createUserPayLinkDirectPay,params)
}

// 根据小票信息获取整个订单信息
export async function getOrderByRecordId(params){
    return await http.post(url.getOrderByRecordId,qs.stringify(params))
}

// 根据小票信息获取整个订单信息
export async function overDueListByBerthNum(params){
    return await http.post(url.overDueListByBerthNum,qs.stringify(params))
}